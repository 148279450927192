import styled from "@/components/contexts/ThemeProvider/styled";
import { unifySansBoldFontFamily } from "@gannettdigital/community-hub-components";
import { Typography } from "@mui/material";

const NoObits = () => {
  return (
    <TextContainer>
      <Title variant="h2">Sorry, no obituaries found.</Title>
      <SubTitle variant="body1">
        Please try a different date range or check the spelling.
      </SubTitle>
    </TextContainer>
  );
};

export default NoObits;

const TextContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginBottom: theme.spacing(16),
}));

const Title = styled(Typography)(({ theme }) => ({
  fontFamily: unifySansBoldFontFamily,
  fontSize: 24,
  marginTop: theme.spacing(4),
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));
