import styled from "@/components/contexts/ThemeProvider/styled";
import { useConfig } from "@/hooks/useAppState";
import { WebLayout } from "@gannettdigital/community-hub-components";
import { useRouter } from "next/router";
import React, { useMemo } from "react";

interface MainProps {
  children: React.ReactNode;
  hideHeaderAndFooter?: boolean;
}

const Main = ({ children, hideHeaderAndFooter }: MainProps) => {
  const config = useConfig();
  const router = useRouter();

  const { header, footer } = useMemo(() => {
    if (hideHeaderAndFooter) {
      return { header: "", footer: "" };
    }
    return config;
  }, [hideHeaderAndFooter, config]);

  const isSlugPage = router.pathname === "/[slug]";

  return (
    <>
      <WebLayout header={header} footer={footer}>
        <>{children}</>
      </WebLayout>

      {/* Slug page has bottom nav for mobile */}
      {isSlugPage && <SlugMobileNavSpace />}
    </>
  );
};

export default Main;

// Extra bottom space for mobile navigation menu
const SlugMobileNavSpace = styled("span")(({ theme }) => ({
  paddingBottom: "70px",

  // no padding for desktop
  [theme.breakpoints.up("md")]: {
    paddingBottom: 0,
  },
}));
