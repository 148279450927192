/* eslint-disable react/react-in-jsx-scope */
import { AnalyticsBody, AnalyticsHeader, Layout } from "@/components/atoms";
import theme from "@/components/contexts/ThemeProvider/theme";
import AnalyticsPageView from "@/components/molecules/AnalyticsPageView";
import { fullyQualifiedLink } from "@/helpers/urls";
import {
  IMarketConfig,
  setIsNativeApp,
  setMarketConfig,
} from "@/redux/actions";
import withReduxStore from "@/redux/withReduxStore";
import { useApollo } from "@/services/apolloClient";
import marketService from "@/services/marketService";
import { ApolloProvider } from "@apollo/client";
import {
  AuthStateProvider,
  SnackbarProvider,
  ThemeProvider,
} from "@gannettdigital/community-hub-components";
import get from "lodash/get";
import { AppInitialProps } from "next/app";
import getConfig from "next/config";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { Provider } from "react-redux";
import { Store } from "redux";

const { publicRuntimeConfig } = getConfig();

interface IMyAppProps {
  Component: any;
  pageProps: AppInitialProps;
  reduxStore: Store;
}

function MyApp({ Component, pageProps, reduxStore }: IMyAppProps): JSX.Element {
  const router = useRouter();
  const apolloClient = useApollo(pageProps);
  const appState = reduxStore.getState().app;
  const { title, domain, publication, desktop_ad_unit, mobile_ad_unit } =
    appState.config;

  function reset() {
    // Scroll to top on every route change
    window.scrollTo(0, 0);

    // Set-up ad framework
    window.gntx = window.gntx || { queue: [] };
    window.gntx.queue.push(() => {
      window.gntx.reset();
    });
  }

  useEffect(() => {
    router.events.on("routeChangeComplete", reset);
    return () => {
      router.events.off("routeChangeComplete", reset);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta name="description" content="" key="desc" />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
        <meta property="og:site_name" content={domain} />
        <meta property="og:title" content={title} key="og_title" />
        <meta
          property="og:url"
          content={fullyQualifiedLink(domain, "/obituaries")}
          key="og_url"
        />
        <meta name="twitter:card" content="summary" key="twitter_card" />
        <meta name="twitter:site" content={`https://${domain}`} />
        <meta name="twitter:title" content={title} key="twitter_title" />
        <AnalyticsHeader />
        {!!publication.site_code && (
          <>
            <script
              type="text/javascript"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: `window.gciAnalyticsUAID = '${publication.site_code}-TEALIUM-COBRAND';window.gciAnalyticsLoadEvents = false;`,
              }}
            />
            <script
              async
              type="text/javascript"
              src={`${publicRuntimeConfig.cdnPrefix}/dcjs/prod/main.js`}
            />
          </>
        )}
      </Head>
      <AnalyticsBody />
      <ThemeProvider theme={theme}>
        <AuthStateProvider
          market={appState.config}
          successRoute={router.basePath + router.asPath}
          env={{
            GUP_AUTHENTICATE_SUBDOMAIN:
              publicRuntimeConfig.GUP_AUTHENTICATE_SUBDOMAIN,
            GUP_USER_SUBDOMAIN: publicRuntimeConfig.GUP_USER_SUBDOMAIN,
          }}
        >
          <AnalyticsPageView />
          <ApolloProvider client={apolloClient}>
            <Provider store={reduxStore}>
              <SnackbarProvider>
                <Layout hideHeaderAndFooter={appState.isNativeApp}>
                  <Component {...pageProps} />
                </Layout>
              </SnackbarProvider>
            </Provider>
          </ApolloProvider>
        </AuthStateProvider>
      </ThemeProvider>
    </>
  );
}

MyApp.getInitialProps = async (appContext: any) => {
  const { ctx, Component } = appContext;
  if (ctx.req) {
    // eslint-disable-next-line no-useless-escape
    const androidNativeRegex = RegExp(/[\(|;]\s*wv\s*[\)|;]/); // (wv; OR ;wv; OR ;wv) OR (wv)
    const iosNativeRegex = RegExp(/euclid/i);
    const userAgent = get(ctx.req, "headers['user-agent']", "");
    const isNativeApp =
      androidNativeRegex.test(userAgent) || iosNativeRegex.test(userAgent);
    ctx.reduxStore.dispatch(setIsNativeApp(isNativeApp));

    // configuration is fetched once server side
    if (marketService.MARKETS.length === 0) {
      await marketService.initialize();
    }

    const host = marketService.getHostValue(ctx.req);
    const config = marketService.getMarketConfig(host) as IMarketConfig;

    // if statement because we are seeing the pod itself calling this function, and the host is set to its own IP. Hence config is undefined.
    if (config !== undefined) {
      ctx.reduxStore.dispatch(setMarketConfig(config));

      // append surrogate keys
      let keys = ctx.res ? ctx.res.getHeader("surrogate-key") : "";
      if (keys && keys.includes("obits-front"))
        keys = `${keys} obits-front-${config.publication.site_code}`;
      ctx.res.setHeader(
        "surrogate-key",
        `obits-web obits-web-${config.publication.site_code} ${keys}`
      );
    } else {
      const fakeConfig: IMarketConfig = {
        id: "",
        title: "",
        domain: "",
        footer: "",
        header: "",
        mobile_ad_unit: "",
        desktop_ad_unit: "",
        external_links: [],
        web_config_filters: [],
        publication: {
          id: "",
          name: "",
          site_code: "",
          publication_info: [],
        },
      };
      ctx.reduxStore.dispatch(setMarketConfig(fakeConfig));
    }
  }

  const pageProps = Component.getInitialProps
    ? await Component.getInitialProps(ctx)
    : {};

  return {
    pageProps: {
      ...pageProps,
    },
  };
};

export default withReduxStore(MyApp);
